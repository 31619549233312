<div class="topNavWrapper">
  <img
    style="cursor: pointer"
    [routerLink]="['/']"
    src="assets/images/unilockedlogopgn-08.png"
    width="118.73px"
    alt="logo"
  />
</div>
<div class="row mainWrapper">
  <div class="col-md-6 col-12 contentWrapper">
    <div class="d-flex flex-column">
      <h1 style="color: #4b53f2"><b>Tekrardan Hoş Geldin!</b></h1>
      <span
        >Unilocked’daki sonsuz keşfine devam etmek için hesap bilgilerini
        gir.</span
      >
    </div>
    <form [formGroup]="firstFormGroup" (ngSubmit)="submit()" class="row">
      <div class="col-12" style="margin-top: 44px">
        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>Eposta</mat-label>
          <input
            style="width: 100%"
            class="w-100"
            matInput
            formControlName="email"
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>Şifre</mat-label>
          <input
            style="width: 100%"
            class="w-100"
            matInput
            type="password"
            formControlName="password"
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col">
            <mat-checkbox id="remember" formControlName="rememberMe">
              Beni bu cihazda hatırla</mat-checkbox
            >
          </div>
          <div class="col d-flex align-items-center justify-content-end">
            <a href="#">Şifremi Unuttum</a>
          </div>
        </div>
      </div>
      <div class="col-12">
        <button
          type="submit"
          mat-flat-button
          color="primary"
          style="width: 100%"
          class="btn primaryButton d-flex align-items-center justify-content-center"
          [loading]="auth.loading$ | async"
        >
          Giriş Yap
        </button>
      </div>
      <div class="col-12 mt-3">
        <a
          style="width: 100%"
          class="btn lightButton d-flex align-items-center justify-content-center"
          [href]="googleAuthUrl"
        >
          <img src="assets/img/google.png" alt="" />
          Google ile giriş yapın
        </a>
      </div>
      <div class="col-12 mt-3 errors" *ngIf="auth.error">
        Lütfen bilgilerinizi kontrol edin.
      </div>
    </form>
  </div>
  <div class="col-md-6 col-12 contentWrapper">
    <div class="mainContentWrapper">
      <div class="contentBody">
        <img
          src="assets/svg/worldIcon.svg"
          width="112"
          height="112"
          alt="worldIcon"
        />
        <h1 class="headerTitle">Herkes için Unilocked</h1>
        <span
          >Unilocked, her türlü sektörden işinde uzman ve geleceğin uzmanlarını
          bir araya getirecek sayısız sayfa bulundurur.</span
        >
      </div>

      <div
        style="
          background-color: #edeefe;
          border-radius: 7px;
          width: 100%;
          padding: 32px;
          margin-top: 10rem;
        "
      >
        <img src="assets/svg/compassIcon.svg" width="24" />
        <div class="row">
          <div class="col-12">
            <h3><b>Keşif</b></h3>
          </div>
          <div class="col-12">
            <span
              >Sizin gibi her zaman bir adım ileri gitmek için can atanlar ile
              tanışın, ilham verene gönderilere göz atın.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
